<template>
  <v-row class="app-preview">
    <v-col
      cols="12"
      md="9"
    >
      <v-card class="app-merchant-editable">
        <!-- Header -->
        <div class="py-5 px-5">
          <div class="d-flex flex-wrap justify-space-between">
            <!-- Date -->
            <div class="d-flex align-center">
              <span class="me-2 font-weight-medium text--primary">Edit Niagamas Merchant</span>
            </div>
          </div>
        </div>

        <v-divider></v-divider>

        <!-- Customer Details -->
        <div class="py-5 px-5">
          <div class="mb-3 d-flex">
            <div class="px-3 flex-grow-1">
              <v-row>
                <!-- Registration Date -->
                <v-col
                  cols="12"
                  sm="6"
                  :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                >
                  <p class="font-weight-semibold">
                    Registration Date:
                  </p>
                  <v-menu
                    v-model="isDateMenuOpen"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :append-icon="icons.mdiCalendar"
                        :value="new Date(merchant.registration_date).toLocaleDateString()"
                        readonly
                        dense
                        outlined
                        hide-details
                        :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="merchant.registration_date"
                      color="primary"
                      :first-day-of-week="1"
                      @input="isDateMenuOpen = false"
                    >
                    </v-date-picker>
                  </v-menu>
                  <RequestHint
                    v-if="request && !isApprovedRequest"
                    :is-attr-change="merchant.registration_date !== request.registration_date"
                    :is-auditor-respond="auditorDisabled"
                    :is-request-void="isRequestVoid"
                    :hint="new Date(request.registration_date).toLocaleDateString('en-SG')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <!-- Name -->
                <v-col
                  cols="12"
                  sm="6"
                  :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                  class="offset-sm-0"
                >
                  <p class="font-weight-semibold">
                    Merchant Name:
                  </p>
                  <v-text-field
                    v-model="merchant.name"
                    single-line
                    dense
                    outlined
                    hide-details
                    placeholder="Merchant Name"
                    :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                  ></v-text-field>
                  <RequestHint
                    v-if="request && !isApprovedRequest"
                    :is-attr-change="merchant.name !== request.name"
                    :is-auditor-respond="auditorDisabled"
                    :is-request-void="isRequestVoid"
                    :hint="request.name"
                  />
                </v-col>

                <!-- Contact Person Number -->
                <v-col
                  cols="12"
                  sm="6"
                  :class="{ 'pr-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                >
                  <p class="font-weight-semibold">
                    Contact Person No.:
                  </p>
                  <v-text-field
                    v-model="merchant.contact_person_number"
                    single-line
                    dense
                    outlined
                    type="number"
                    :rules="[v => /[0-9\-().\s]{7}$/.test(v) || 'Must be valid contact number']"
                    hide-details="auto"
                    placeholder="Contact Number"
                    prefix="+673"
                    :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                  ></v-text-field>
                  <RequestHint
                    v-if="request && !isApprovedRequest"
                    :is-attr-change="merchant.contact_person_number !== request.contact_person_number"
                    :is-auditor-respond="auditorDisabled"
                    :is-request-void="isRequestVoid"
                    :hint="request.contact_person_number"
                  />
                </v-col>

                <!-- ROC Number -->
                <v-col
                  cols="12"
                  sm="6"
                  :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                >
                  <p class="font-weight-semibold">
                    ROC No.:
                  </p>
                  <v-text-field
                    v-model="merchant.roc_number"
                    single-line
                    dense
                    outlined
                    hide-details="auto"
                    placeholder="IC Number"
                    :disabled="auditorDisabled || voidRequestDisabled || isApprovedRequest"
                  ></v-text-field>
                  <RequestHint
                    v-if="request && !isApprovedRequest"
                    :is-attr-change="merchant.roc_number !== request.roc_number"
                    :is-auditor-respond="auditorDisabled"
                    :is-request-void="isRequestVoid"
                    :hint="request.roc_number"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-if="merchant.merchant_cert_url || request.request_merchant_cert_url"
                  cols="12"
                  sm="6"
                  :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                >
                  <p class="font-weight-semibold mb-0">
                    16/17 Attachment
                  </p>

                  <div v-if="!merchant.merchant_cert_url">
                    <small
                      v-if="request.request_merchant_cert_url !== 'Not Found'"
                      class="primary--text"
                    >
                      Empty -->
                    </small>
                    <a
                      v-if="request.request_merchant_cert_url !== 'Not Found'"
                      :href="request.request_merchant_cert_url"
                      target="_blank"
                      class="font-weight-semibold"
                    >
                      <small>{{ request.request_merchant_cert_name }}</small>
                      <v-icon
                        size="18"
                        color="primary"
                      >{{ icons.mdiOpenInNew }}</v-icon>
                    </a>
                    <div v-else>
                      <small class="error--text">
                        Requested attachment seems to be missing or corrupted, please ask counter to reattach again
                      </small>
                    </div>
                  </div>

                  <div v-else>
                    <a
                      :href="merchant.merchant_cert_url"
                      target="_blank"
                    >
                      <small>{{ merchant.merchant_cert_name }}</small>
                      <v-icon
                        size="18"
                        color="primary"
                      >{{ icons.mdiOpenInNew }}</v-icon>
                    </a>
                    <div v-if="request.request_merchant_cert_url !== 'Not Found'">
                      <RequestHint
                        v-if="request && request.request_merchant_cert_url && !isApprovedRequest"
                        :is-attr-change="merchant.merchant_cert !== request.merchant_cert"
                        :is-auditor-respond="auditorDisabled"
                        :is-request-void="isRequestVoid"
                        :hint="request.request_merchant_cert_name"
                        :link="request.request_merchant_cert_url"
                      />
                    </div>
                    <div v-else>
                      <small v-if="!isRequestCreate" class="error--text">
                        Requested attachment seems to be missing or corrupted, please ask counter to reattach again
                      </small>
                    </div>
                  </div>

                  <v-file-input
                    v-if="!(auditorDisabled || voidRequestDisabled || isApprovedRequest)"
                    v-model="newCert"
                    prepend-icon=""
                    :append-icon="icons.mdiPaperclip"
                    accept="application/pdf, image/png, image/jpg, image/jpeg"
                    show-size
                    dense
                    outlined
                    :rules="certUploadRules"
                    :label="merchant.merchant_cert_url ? 'Upload new 16/17' : 'Upload 16/17'"
                    hide-details="auto"
                    class="mt-4"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip
                        small
                        label
                        color="primary"
                      >
                        {{ text }}
                      </v-chip>
                    </template>

                    <template v-slot:append>
                      <v-progress-circular
                        v-if="uploadLoading"
                        color="primary"
                        indeterminate
                        size="25"
                      ></v-progress-circular>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      md="3"
    >
      <v-card
        v-if="!isApprovedRequest"
        class="mb-6"
      >
        <v-card-text>
          <!-- Auditor view request -->
          <v-btn
            v-if="can('write', 'ReportActions') && requestId"
            color="primary"
            block
            depressed
            :disabled="isRespondedRequest || merchant.deleted_at"
            class="mb-2"
            @click="isApproveDialogVisible = true"
          >
            {{ t('Approve Request') }}
          </v-btn>

          <!-- Auditor view request -->
          <v-btn
            v-if="can('write', 'ReportActions') && requestId"
            color="primary"
            outlined
            depressed
            block
            :disabled="isRespondedRequest"
            @click="isRejectDialogVisible = true"
          >
            {{ t('Reject Request') }}
          </v-btn>

          <!-- Create & edit receipt -->
          <v-btn
            v-else-if="can('write', 'ReportActions') && userRole === 'Auditor'"
            color="primary"
            depressed
            block
            :loading="loading"
            :disabled="loading"
            @click="handleUploads('auditor')"
          >
            {{ t('Confirm Merchant') }}
          </v-btn>

          <!-- Void request -->
          <v-btn
            v-else-if="router.currentRoute.params.action === 'Void' || merchant.deleted_at"
            color="error"
            depressed
            block
            :disabled="isApprovedRequest"
            @click="isVoidDialogVisible = true"
          >
            {{ t('Remove Request') }}
          </v-btn>

          <!-- Create & edit request -->
          <v-btn
            v-else
            color="primary"
            depressed
            block
            :disabled="isApprovedRequest"
            @click="isCommentDialogVisible = true"
          >
            {{ t('Send Request') }}
          </v-btn>

          <div
            v-if="merchant.deleted_at"
            class="text-center mt-4"
          >
            {{ can('write', 'ReportActions') ? t('Merchant has been voided, please inform requester to remove this request.')
              : t('Merchant has been voided, please remove this request as it cannot be saved.') }}
          </div>

          <div
            v-else-if="!auditorDisabled && router.currentRoute.params.action !== 'Void'"
            class="text-center mt-4"
          >
            {{ t('Please ensure that all fields are filled correctly as this record will not be editable after confirming.') }}
          </div>
        </v-card-text>
      </v-card>
      <v-card
        v-if="errors.length != 0"
        class="mb-6"
      >
        <v-card-text>
          <Alert
            :messages="errors"
            color="error"
          ></Alert>
        </v-card-text>
      </v-card>
      <v-card
        v-if="requestId && !isApprovedRequest && can('write', 'ReportActions')"
        class="mb-6"
      >
        <v-card-text v-if="request.requested_by">
          <div v-if="isRequestVoid">
            {{ request.requested_by.nickname }} has requested to void this merchant.
          </div>
          <div v-else-if="isRequestCreate">
            {{ request.requested_by.nickname }} has requested to create this merchant.
          </div>
          <div v-else>
            <div class="mb-2">
              {{ request.requested_by.nickname }} has requested to edit this merchant.
            </div>
            <div>The <span class="primary--text font-weight-semibold">requested changes</span> is hinted below each merchant field, ensure these changes are appropriate as this merchant will not be reversible after approval.</div>
          </div>
        </v-card-text>
      </v-card>
      <v-card
        v-if="isApprovedRequest"
        class="mb-6"
      >
        <v-card-text>
          <div>
            This request has been approved & no longer editable.
          </div>
        </v-card-text>
      </v-card>
      <RequestComments
        v-if="requestId"
        :comments="comments"
        :fetch-comments="fetchComments"
      />
    </v-col>

    <!-- Void dialog -->
    <v-dialog
      v-model="isVoidDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Void request on {{ request.record_number || merchant.name }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isVoidDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          This request will be voided. It won't be taken into any calculations.
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="error"
            block
            :loading="voidLoading"
            :disabled="voidLoading"
            class="mt-3"
            @click="voidRequest()"
          >
            Yes, remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Comment dialog -->
    <v-dialog
      v-model="isCommentDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Request changes?
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isCommentDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-textarea
            v-model="newComment"
            dense
            outlined
            hide-details="auto"
            placeholder="Leave your comment here (optional)"
            :disabled="loading"
            class="mb-4"
          ></v-textarea>
          <p>
            {{ requestId ? 'This updates the edit request & changes status to Pending for auditor to respond.' :
              'This action will create an edit request & the changes will be saved after approved by auditor.' }}
          </p>
          <p class="mb-0">
            Please leave any remarks that the auditor should know about the changes.
          </p>
        </v-card-text>

        <v-card-actions class="mt-2">
          <v-btn
            color="primary"
            block
            :loading="loading"
            :disabled="loading"
            @click="handleUploads('clerk')"
          >
            Yes, send request
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Approve dialog -->
    <v-dialog
      v-model="isApproveDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Approve request
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isApproveDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="newComment"
            dense
            outlined
            hide-details="auto"
            placeholder="Leave your comment here (optional)"
            :disabled="loading"
            class="mb-1"
          ></v-text-field>
          This action will update merchant with the request changes, ensure that all fields are filled correctly as this record will not be editable after approval.
        </v-card-text>

        <v-card-actions class="mt-2">
          <v-btn
            color="primary"
            block
            :loading="loading"
            :disabled="loading"
            @click="approveRequest()"
          >
            Save merchant
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Reject dialog -->
    <v-dialog
      v-model="isRejectDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Reject request
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isRejectDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="newComment"
            dense
            outlined
            hide-details="auto"
            placeholder="Leave your comment here (optional)"
            :disabled="loading"
            class="mb-1"
          ></v-text-field>
          This update the request & change status to Rejected, comment on the reason for rejecting the requested change.
        </v-card-text>

        <v-card-actions class="mt-2">
          <v-btn
            color="primary"
            block
            :loading="loading"
            :disabled="loading"
            @click="rejectRequest()"
          >
            Reject request
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mdiCalendar, mdiPaperclip } from '@mdi/js'
import {
  inject,
  onMounted,
  ref,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'
import Alert from '@/components/Alert'
import RequestHint from '@/components/apps/request/RequestHint'
import RequestComments from '@/components/apps/request/RequestComments'

export default {
  components: {
    Alert,
    RequestHint,
    RequestComments,
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')
    const can = inject('can')
    const { router } = useRouter()
    const requestId = router.currentRoute.params.request_id

    // Properties
    const merchant = ref({
      registration_date: new Date().toISOString().substring(0, 10),
      name: null,
      contact_person_number: null,
      roc_number: null,
      merchant_cert: null,
    })
    const request = ref({})
    const comments = ref([])
    const errors = ref([])
    const userData = JSON.parse(localStorage.getItem('userData'))
    const userRole = userData && userData.role ? userData.role : null
    const dateToday = new Date().toLocaleDateString('en-SG')
    const newCert = ref(null)
    const uploadedCert = ref(null)
    const loading = ref(false)
    const voidLoading = ref(false)
    const auditorDisabled = ref(false)
    const voidRequestDisabled = ref(false)
    const isVoidDialogVisible = ref(false)
    const isApproveDialogVisible = ref(false)
    const isRejectDialogVisible = ref(false)
    const isRequestVoid = ref(false)
    const isRequestCreate = ref(false)
    const isApprovedRequest = ref(false)
    const isRespondedRequest = ref(false)
    const isDateMenuOpen = ref(false)
    const isCommentDialogVisible = ref(false)
    const newComment = ref(null)
    const uploadLoading = ref(false)
    const certUploadRules = [value => !value || value.size < 5000000 || 'Certificate file has to be lower than 5MB']

    // Methods
    const fetchRequest = () => {
      store
        .dispatch('requestStore/fetchRequest', { id: requestId })
        .then(response => {
          let merchantAttrsInField = response.data.data.record
          if (response.data.data.approved_at) {
            isApprovedRequest.value = true
            merchantAttrsInField = response.data.data.result
          }
          const existingMerchant = merchantAttrsInField
          merchant.value.id = response.data.data.record.id
          merchant.value.created_at = existingMerchant.created_at
          merchant.value.name = existingMerchant.name
          merchant.value.roc_number = existingMerchant.roc_number
          merchant.value.contact_person_number = existingMerchant.contact_person_number
          merchant.value.registration_date = new Date(existingMerchant.registration_date.replace(/(..)\/(..)\/(....)/, '$3-$2-$1')).toISOString().substring(0, 10)
          if (existingMerchant.merchant_cert_url) {
            merchant.value.merchant_cert_url = existingMerchant.merchant_cert_url
            merchant.value.merchant_cert_name = existingMerchant.merchant_cert_name
          }
          if (response.data.data.result) {
            request.value = response.data.data.result
            request.value.registration_date = new Date(request.value.registration_date.replace(/(..)\/(..)\/(....)/, '$3-$2-$1')).toISOString().substring(0, 10)
          }
          request.value.requested_by = response.data.data.requested_by
          request.value.responded_by = response.data.data.responded_by
          request.value.record_number = response.data.data.record.receipt_number
          request.value.requested_at = response.data.data.requested_at
          isRequestVoid.value = response.data.data.action === 'Void'
          isRequestCreate.value = response.data.data.action === 'Create'
          if (response.data.data.responded_by) isRespondedRequest.value = true
          if (response.data.data.approved_at) isApprovedRequest.value = true
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching request. Please refresh!')
        })
    }
    const fetchRequestedMerchant = () => {
      store
        .dispatch('requestStore/fetchRequest', { id: requestId })
        .then(response => {
          const returnedMerchant = response.data.data.result

          merchant.value.id = response.data.data.record.id
          merchant.value.created_at = returnedMerchant.created_at
          merchant.value.name = returnedMerchant.name
          merchant.value.roc_number = returnedMerchant.roc_number
          merchant.value.contact_person_number = returnedMerchant.contact_person_number
          merchant.value.registration_date = new Date(returnedMerchant.registration_date.replace(/(..)\/(..)\/(....)/, '$3-$2-$1')).toISOString().substring(0, 10)
          if (returnedMerchant.merchant_cert_url) {
            merchant.value.merchant_cert_url = returnedMerchant.merchant_cert_url
            merchant.value.merchant_cert_name = returnedMerchant.merchant_cert_name
          }
          if (response.data.data.approved_at) isApprovedRequest.value = true
          merchant.value.deleted_at = returnedMerchant.deleted_at
          isRequestCreate.value = response.data.data.action === 'Create'
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching request. Please refresh!')
        })
    }
    const fetchMerchant = () => {
      store
        .dispatch('customerStore/fetchNiagamasMerchant', { id: router.currentRoute.params.id })
        .then(response => {
          const existingMerchant = response.data.data

          merchant.value.id = existingMerchant.id
          merchant.value.name = existingMerchant.name
          merchant.value.contact_person_number = existingMerchant.contact_person_number
          merchant.value.roc_number = existingMerchant.roc_number
          merchant.value.registration_date = new Date(existingMerchant.registration_date.replace(/(..)\/(..)\/(....)/, '$3-$2-$1')).toISOString().substring(0, 10)
          merchant.value.merchant_cert = existingMerchant.merchant_cert
          merchant.value.merchant_cert_url = existingMerchant.merchant_cert_url
          merchant.value.merchant_cert_name = existingMerchant.merchant_cert_name
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching merchant. Please refresh!')
        })
    }
    const updateMerchant = (cert = null) => {
      delete merchant.value.merchant_cert_url
      delete merchant.value.merchant_cert_name
      if (cert) {
        merchant.value.merchant_cert = cert.signed_id
      }
      const updateParam = {
        id: router.currentRoute.params.id,
        niagamas_merchant: merchant.value,
      }
      store
        .dispatch('customerStore/updateNiagamasMerchant', updateParam)
        .then(response => {
          loading.value = false
          snackbarService.success(response.data.message)
          router.push('/apps/profile-creation/list')
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while updating merchant. Please refresh!')
        })
    }
    const createComment = id => {
      store
        .dispatch('requestStore/createComment', {
          user_id: userData.id,
          approval_request_id: id,
          comment: newComment.value,
        })
        .then(response => {
          snackbarService.success(response.data.message)
          router.push('/apps/requests/list')
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while saving comment. Please refresh!')
        })
    }
    const saveRequest = (cert = null) => {
      loading.value = true
      delete merchant.value.merchant_cert
      delete merchant.value.merchant_cert_url
      delete merchant.value.merchant_cert_name
      if (cert) {
        merchant.value.merchant_cert = cert.signed_id
      }
      const requestParam = {
        record_id: merchant.value.id,
        record_type: 'NiagamasMerchant',
        requested_by_id: userData.id,
        action: isRequestCreate.value ? 'Create' : 'Edit',
        params: merchant.value,
        rejected_at: null,
        responded_by: null,
        comment: newComment.value,
      }
      if (cert) {
        requestParam.merchant_cert = cert.signed_id
      }
      if (requestId) requestParam.id = requestId

      store
        .dispatch(`requestStore/${requestId ? 'updateRequest' : 'createRequest'}`, requestParam)
        .then(response => {
          if (newComment.value) {
            createComment(response.data.data.id)
          } else {
            snackbarService.success(response.data.message)
            router.push('/apps/requests/list')
          }
        })
        .catch(error => {
          loading.value = false
          isCommentDialogVisible.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while saving request. Please refresh!')
        })
    }
    const approveRequest = () => {
      loading.value = true
      store
        .dispatch('requestStore/approveRequest', { id: requestId, comment: newComment.value })
        .then(response => {
          if (newComment.value) {
            createComment()
          } else {
            snackbarService.success(response.data.message)
            router.push('/apps/requests/list')
          }
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while approving request. Please refresh!')
        })
    }
    const rejectRequest = () => {
      loading.value = true
      store
        .dispatch('requestStore/rejectRequest', { id: requestId, comment: newComment.value })
        .then(response => {
          if (newComment.value) {
            createComment()
          } else {
            snackbarService.success(response.data.message)
            router.push('/apps/requests/list')
          }
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while rejecting request. Please refresh!')
        })
    }
    const voidRequest = () => {
      voidLoading.value = true
      store
        .dispatch('requestStore/voidRequest', { id: requestId })
        .then(response => {
          snackbarService.success(response.data.message)
          router.push('/apps/requests/list')
        })
        .catch(error => {
          voidLoading.value = false
          snackbarService.error(error.data.message || 'Something went wrong while voiding request. Please refresh!')
        })
    }
    const isNumber = event => {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      const keyPressed = event.key
      if (!keysAllowed.includes(keyPressed)) {
        event.preventDefault()
      }
    }
    const roleUpdate = role => {
      if (role === 'auditor') {
        updateMerchant(uploadedCert.value)
      } else if (role === 'clerk') {
        saveRequest(uploadedCert.value)
      }
    }
    const directUploadCert = (file, role) => {
      uploadLoading.value = true
      store
        .dispatch('customerStore/directUploadMerchantCert', file)
        .then(response => {
          uploadLoading.value = false
          if (response) {
            uploadedCert.value = response
            roleUpdate(role)
          }
        })
        .catch(error => {
          uploadLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while uploading certificate. Please refresh!')
        })
    }
    const handleUploads = role => {
      const uploadFile = newCert.value
      if (uploadFile) directUploadCert(uploadFile, role)
      if (!newCert.value) roleUpdate(role)
    }
    const fetchComments = () => {
      store
        .dispatch('requestStore/fetchComments', { request_id: requestId })
        .then(response => {
          const { data } = response.data
          comments.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching comments. Please refresh!')
        })
    }
    const isMerchantEdit = () => {
      if (requestId && can('write', 'ReportActions')) {
        // Auditor view request
        auditorDisabled.value = true
        fetchRequest()
      } else if (router.currentRoute.params.action === 'Void') {
        voidRequestDisabled.value = true
        fetchRequest()
      } else if (requestId) {
        // Clerk edit request
        fetchRequestedMerchant()
      } else {
        // Edit invoice / create request
        fetchMerchant()
      }
    }

    // Mounted
    onMounted(() => {
      if (requestId) fetchComments()
      isMerchantEdit()
    })

    return {
      t,
      can,
      router,
      merchant,
      request,
      requestId,
      comments,
      errors,
      fetchRequest,
      fetchRequestedMerchant,
      saveRequest,
      voidRequest,
      approveRequest,
      rejectRequest,
      userData,
      dateToday,
      loading,
      isNumber,
      newCert,
      directUploadCert,
      handleUploads,
      uploadLoading,
      certUploadRules,
      isDateMenuOpen,
      isCommentDialogVisible,
      isVoidDialogVisible,
      isApproveDialogVisible,
      isRejectDialogVisible,
      newComment,
      fetchComments,
      fetchMerchant,
      updateMerchant,
      auditorDisabled,
      voidRequestDisabled,
      isApprovedRequest,
      isRespondedRequest,
      isRequestVoid,
      isRequestCreate,
      voidLoading,
      userRole,

      icons: {
        mdiCalendar,
        mdiPaperclip,
      },
    }
  },
}
</script>
